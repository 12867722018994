import locale from './locale'

export const isPhone = (value) => {
  if (!value) {
    return true
  }

  const size = value.length

  if (size === 10 || size === 11) {
    return true
  }

  return false
}

export function phone(message = locale.string.phone) {
  return this.test('phone', message, isPhone)
}

export default phone
