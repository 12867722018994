import { isValid as isValidCpf } from '../cpf'
import locale from './locale'

export const isValid = (value) => {
  if (!value) {
    return true
  }

  return isValidCpf(value)
}

export function cpf(message = locale.string.cpf) {
  return this.test('cpf', message, isValid)
}

export default cpf
