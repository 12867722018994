// @ts-ignore
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const push = (path, state) => history.push(path, state)

const replace = (path, state) => history.replace(path, state)

const redirectToPortal = () => {
  const loginRoutes = ['/', '/login']
  const mainRoute = history.location.pathname

  if (loginRoutes.includes(mainRoute)) {
    history.push('/propostas')
  }
}

const clearStateKey = (key) => {
  if (history.location && history.location.state && history.location.state[key]) {
    const state = { ...history.location.state }
    delete state[key]
    history.replace({ ...history.location, state })
  }
}

export default {
  history,
  push,
  replace,
  redirectToPortal,
  clearStateKey
}
