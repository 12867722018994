const BLACKLIST = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999'
]

const LOOSE_STRIP_REGEX = /[^\d]+/g

export function sum(index, cpf) {
  let sum = 0

  for (let i = 0; i < index; i = i + 1) {
    sum += parseInt(cpf.charAt(i), 10) * (index + 1 - i)
  }

  return sum
}

export function verifierDigitAt(index, cpf) {
  let rev = 11 - (sum(index, cpf) % 11)

  if (rev === 10 || rev === 11) {
    rev = 0
  }

  if (rev !== parseInt(cpf.charAt(index), 10)) {
    return false
  }

  return true
}

export function verifierDigitsAt(indexes, cpf) {
  // eslint-disable-next-line
  for (const index of indexes) {
    if (!verifierDigitAt(index, cpf)) {
      return false
    }
  }

  return true
}

export function strip(cpf) {
  const regex = LOOSE_STRIP_REGEX
  return (cpf || '').toString().replace(regex, '')
}

export function format(cpf) {
  return strip(cpf).replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

export function isValid(cpf) {
  const stripped = strip(cpf)

  if (!stripped) {
    return false
  }

  if (stripped.length !== 11) {
    return false
  }

  if (BLACKLIST.includes(stripped)) {
    return false
  }

  return verifierDigitsAt([9, 10], stripped)
}

export default {
  isValid,
  format,
  strip,
  verifierDigitsAt,
  verifierDigitAt
}
