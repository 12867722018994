import locale from './locale'

export const isValidCellphone = (value) => {
  return !value ? false : `${value}`.length === 11
}

export function cellphone(message = locale.string.cellphone) {
  return this.test('cellphone', message, isValidCellphone)
}

export default cellphone
