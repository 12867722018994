const validatorRequestParams = {
  account: 'account',
  bank_code: 'bank',
  agency: 'agency',
  account_digit: 'accountDigit',
  account_type: 'accountType'
}

const validatorAccountTypes = {
  savings: 'CONTA_POUPANCA',
  current: 'CONTA_CORRENTE'
}

const formatRequest = (bankData, cpf) => {
  if (!bankData || !cpf) {
    return {}
  }

  const { account, accountDigit, accountType, agency, bank } = bankData

  return {
    account: account,
    account_digit: accountDigit,
    account_type: validatorAccountTypes[accountType],
    agency: agency,
    bank_code: bank,
    cpf_cnpj: cpf
  }
}

const formatErrors = (bankDataErrors) => {
  if (!bankDataErrors) {
    return []
  }

  const errors = {}

  bankDataErrors.forEach((error) => {
    errors[validatorRequestParams[error.field]] = error.message
  })

  return errors
}

export default { formatErrors, formatRequest }
