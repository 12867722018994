import { parseDate, isValidDate } from '../date'

export const DATE_REGEX = /(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d{2}$/

export function isValid() {
  return this.transform((value, originalValue) => {
    value = parseDate(originalValue, 'dd/MM/yyyy')

    if (!isValidDate(value) || !DATE_REGEX.test(originalValue)) {
      return new Date('')
    }

    return value
  })
}

export default isValid
