import { endOfDay } from 'date-fns'
import locale from './locale'

export function disableFuture(exact = false, message = locale.date.disableFuture) {
  const now = new Date()

  return this.max(exact ? now : endOfDay(now), message)
}

export default disableFuture
