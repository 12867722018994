import * as yup from 'yup'
import locale from './locale'

export function setLocale(custom) {
  if (!custom) {
    return yup.setLocale(locale)
  }

  Object.keys(custom).forEach((type) => {
    Object.keys(custom[type]).forEach((method) => {
      if (!locale[type]) {
        locale[type] = {}
      }

      locale[type][method] = custom[type][method]
    })
  })

  return yup.setLocale(locale)
}
