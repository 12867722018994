import yupLocale from 'yup/lib/locale'

const notType = yupLocale.mixed.notType

const locale = {
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório',
    notType: (...args) => {
      console.error('ValidationError notType:', notType(...args))

      if (args.length && args[0].type === 'date') {
        return 'Data inválida'
      }

      return 'Tipo inválido'
    }
  },
  string: {
    companyNumber: 'Campo inválido',
    cellphone: 'Telefone inválido',
    email: 'E-mail inválido',
    min: 'Mínimo de ${min} caracteres',
    max: 'Máximo de ${max} caracteres',
    cpf: 'CPF inválido',
    cnpj: 'CNPJ inválido',
    ddd: 'DDD inválido',
    phone: 'Telefone inválido',
    identificationDocument: 'CNPJ ou CPF inválido',
    billet: 'Boleto inválido',
    zipCode: 'CEP inválido',
    documentText: 'Campo inválido'
  },
  number: {
    size: 'Máximo de ${max} caracteres',
    positive: 'Valor deve ser maior que 0',
    min: 'O valor deve ser maior ou igual a ${min}',
    max: 'O valor deve ser menor ou igual a ${max}'
  },
  date: {
    disableFuture: 'Não é permitido datas futuras'
  }
}

export default locale
