import { isOnlyLetter } from '../string'
import locale from './locale'

export const isValidDocumentText = (_, { originalValue }) => {
  if (!originalValue) {
    return true
  }

  return isOnlyLetter(originalValue)
}

export function documentText(message = locale.string.documentText) {
  return this.trim().min(3).test('documentText', message, isValidDocumentText)
}

export default documentText
