import locale from './locale'

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i

export const isEmail = (value) => EMAIL_REGEX.test(value)

export function email(message = locale.string.email) {
  return this.test('email', message, isEmail)
}

export default email
