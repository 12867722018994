import BigNumber from 'bignumber.js'

const getLanguage = () => 'pt-BR'

export const formatNumber = (number, country = getLanguage()) => {
  if (isNaN(number)) {
    return ''
  }

  return new Intl.NumberFormat(country).format(number)
}

export const formatPercent = (v) => (formatNumber(v) === '' ? v : `${formatNumber(v)}%`)

export const getPercentage = (subTotal, total, decimal = 2, absoluteValue = false) => {
  if (!subTotal) {
    return Number(0).toFixed(decimal)
  }

  let increase = new BigNumber(total).minus(subTotal).dividedBy(subTotal)

  if (absoluteValue) {
    increase = increase.absoluteValue()
  }

  return increase.multipliedBy(100).toFixed(decimal)
}

export const removeCurrency = (value, currency) => value.toString().replace(currency, '')

export const convertStringToNumber = (value) => Number(value)

export const convertToDecimal = (value) => Number(value).toFixed(2)

export const formatToDecimalNumber = (value) => {
  const removeDots = value.toString().split('.').join('')
  const replaceCommaToDot = removeDots.replace(',', '.')
  return convertToDecimal(replaceCommaToDot)
}

export const convertCurrencyToNumber = (value, currency) => {
  convertStringToNumber(formatToDecimalNumber(removeCurrency(value, currency)))
}

export const isNumber = (value) => /^[1-9]\d{0,2}(\d{3})*(.\d+)?$/.test(value)

export const getValueSubtractedByPercentage = (total, percentage, decimal = 2) => {
  const percentageValue = new BigNumber(total).multipliedBy(percentage).dividedBy(100)

  return new BigNumber(total).minus(percentageValue).toFixed(decimal)
}

export const divide = (a, b) => new BigNumber(a).dividedBy(b).toNumber()

export const inRange = (x, min, max) => (x - min) * (x - max) <= 0

export default {
  divide,
  convertStringToNumber,
  convertToDecimal,
  convertCurrencyToNumber,
  formatNumber,
  formatPercent,
  formatToDecimalNumber,
  getPercentage,
  getValueSubtractedByPercentage,
  isNumber,
  inRange,
  removeCurrency
}
