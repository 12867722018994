import moment from './moment'
import { formatDateUTC } from './date'

const MAXIMUM_AGE = 55

export const getAge = (date) => moment().utc(false).diff(date, 'years', false)

export const getBirthdateAndAge = (date) => {
  if (!date) {
    return ''
  }

  const age = getAge(date)

  const alertAge = age > MAXIMUM_AGE ? '⚠️' : ''

  const renderAge = `(${age}) anos ${alertAge}`

  return age > 0 && `${formatDateUTC(date, 'DD/MM/YYYY')} ${renderAge}`
}

export default {
  getAge,
  getBirthdateAndAge
}
