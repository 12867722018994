import { DDDS_BRAZIL } from '@base39/constants'
import locale from './locale'

export const isValidDDD = (value) => {
  if (!value) {
    return true
  }

  const ddd = value.substring(0, 2)

  return DDDS_BRAZIL.some((item) => item.code === ddd)
}

export function ddd(message = locale.string.ddd) {
  return this.test('ddd', message, isValidDDD)
}

export default ddd
