export const filterNotNums = (value = '') => value?.replace(/\D/g, '') || ''

export const escapeHTML = (text) => (text ? text.replace(/(<([^>]+)>)|&nbsp;/gi, '') : '')

export const getUniqueId = () =>
  (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10)

export const ellipsis = (text, size) => {
  if (text && text.length > size) {
    return `${text.slice(0, size)}...`
  }

  return text
}

export const formatPhone = (value) => {
  if (!value) {
    return ''
  }

  const phone = value.replace('+55', '')

  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
  }
  return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
}

export const isPlural = (number) => number > 1

export const isEmpty = (value) => !value || value.toString().length === 0

export const getPluralized = (number) => (key) => isPlural(number) ? `${key}-plural` : key

export const formatZip = (zipCode) =>
  filterNotNums(zipCode)?.replace(/(\d{5})(\d{3})/, '$1-$2')

export const formatCPF = (value) => {
  if (!value) return ''

  return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
}

export const formatCNPJ = (value) => {
  if (!value) return ''

  return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
}

export const formatDocument = (value) => {
  if (!value) return ''

  if (value.length === 11) {
    return formatCPF(value)
  }

  return formatCNPJ(value)
}

export const getFirstWord = (str) => {
  if (typeof str !== 'string' || !str) return str

  return str.replace(/ .*/, '')
}

export const removeAccent = (text) =>
  text && text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const removeSpecialCaracters = (text) => text && text.replace(/[^\w\s]/gi, '')

export const clear = (text) =>
  text ? removeAccent(removeSpecialCaracters(text.toString().replace(/\s/g, ''))) : ''

export const formatBillet = (value) => {
  if (!value) return ''

  return value.replace(
    /(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/g,
    '$1.$2 $3.$4 $5.$6 $7 $8'
  )
}

export const formatString = (x) => removeAccent(String(x))?.trim().toLowerCase()

export const isEqual = (x, y) => formatString(x) === formatString(y)

export const REGEX_ONLY_LETTER_AND_SPACE = /[^a-zÀ-ž ]/i

export const REGEX_ONLY_LETTER = /[^a-zÀ-ž]/i

export const isOnlyLetter = (value, { space = true } = {}) => {
  if (!value) {
    return false
  }

  const INVALID_REGEX = space ? REGEX_ONLY_LETTER_AND_SPACE : REGEX_ONLY_LETTER

  return !INVALID_REGEX.test(value)
}

export const getFirstAndLastName = (name) => {
  if (!name) {
    return ''
  }

  const names = name
    .trim()
    .replace(/( {2})+/g, ' ')
    .split(' ')

  const firstName = names[0]?.trim()

  if (!firstName) {
    return ''
  }

  if (names.length === 1) {
    return firstName
  }

  const lastName = names[names.length - 1]?.trim()

  return `${firstName} ${lastName}`
}

export default {
  isPlural,
  getFirstAndLastName,
  isEmpty,
  getPluralized,
  filterNotNums,
  formatZip,
  escapeHTML,
  formatPhone,
  getUniqueId,
  ellipsis,
  formatCPF,
  formatCNPJ,
  formatDocument,
  getFirstWord,
  removeAccent,
  clear,
  removeSpecialCaracters,
  formatBillet,
  isOnlyLetter,
  formatString,
  isEqual
}
