import moment from './moment'
import { parse, format, add, differenceInMonths, startOfMonth } from 'date-fns'

export const getNow = moment

const toMoment = (date) => moment(date)

export const formatDate = (date, format = 'L', utc = true) =>
  date ? moment(date).utc(utc).format(format) : null

export const formatDateUTC = (date, format) => formatDate(date, format, false)

export const dateDiff = (dateA, dateB, diff) => moment(dateA).diff(moment(dateB), diff)

export const isValidDate = (date) => moment(date, 'L').isValid()

export const convertDateToUTC = (date) => moment(date).utc(false).valueOf()

export const formatToISO = (date) => moment(date).toISOString()

export const addDays = (add, date = getNow()) => date.add(add, 'days')

export const subtractDays = (subtract, date = getNow()) =>
  date.startOf('day').subtract(subtract, 'days')

export const getStartDay = (date) => moment(date).startOf('day')

export const getEndDay = (date) => moment(date).endOf('day')

export const getStartMonth = (date) => moment(date).startOf('month').toISOString()

export const getEndMonth = (date) => moment(date).endOf('month').toISOString()

export const startOf = (date, param) => moment(date).startOf(param)

export const endOf = (date, param) => moment(date).endOf(param)

export const isAfter = (date, beforeDate = getNow()) => moment(date).isAfter(beforeDate)

export const getMonthFromDate = (date, currentDate) => {
  const diffDateLeft = currentDate ? new Date(currentDate) : new Date()
  const diffDateRight = new Date(date)

  return differenceInMonths(diffDateLeft, diffDateRight)
}

export const getMonthDiffStartOfMonth = (date, currentDate) => {
  const diffDateLeft = currentDate ? new Date(currentDate) : new Date()
  const diffDateRight = new Date(date)

  const diffMonths = differenceInMonths(
    startOfMonth(diffDateLeft),
    startOfMonth(diffDateRight)
  )

  return diffMonths
}

export const parseDate = (date, formatReference) =>
  parse(date, formatReference, new Date())

export const dateFnsFormat = (date, pattern) => format(date, pattern)

export const dateFnsAdd = (date, duration) => add(date, duration)

const currentDay = moment().format('D')

export const parseStringToISO = (dateString) => {
  if (!dateString) {
    return null
  }
  const parsedDate = parseDate(dateString, 'dd/MM/yyyy')
  return parsedDate ? parsedDate.toISOString() : parsedDate
}

export const parseDateToLocaleString = (date) => new Date(date).toLocaleDateString()

const minBirthdayDate = new Date('01/01/1900')

export default {
  addDays,
  convertDateToUTC,
  currentDay,
  dateDiff,
  endOf,
  formatDate,
  formatDateUTC,
  formatToISO,
  getEndDay,
  getEndMonth,
  getMonthFromDate,
  getMonthDiffStartOfMonth,
  getNow,
  getStartDay,
  getStartMonth,
  isAfter,
  isValidDate,
  parseDate,
  startOf,
  subtractDays,
  toMoment,
  parseStringToISO,
  parseDateToLocaleString,
  minBirthdayDate,
  dateFnsFormat,
  dateFnsAdd
}
