// @ts-ignore
import { getIn } from 'formik'
import StringUtils from './string'

export const get = (obj, path, defaultValue = null) => getIn(obj, path, defaultValue)

export const isObjectEmpty = (obj) => !Object.keys(obj || {}).length

export const allObjectKeysIsValid = (obj) => Object.keys(obj).every((key) => obj[key])

export const converObjToArray = (obj) => Object.values(obj)

export const getMinObjValue = (obj) => {
  const values = converObjToArray(obj)
  return Math.min(...values)
}

export const getMaxObjValue = (obj) => {
  const values = converObjToArray(obj)
  return Math.max(...values)
}

export const sanitizeObject = (data) => {
  const values = { ...data }
  Object.keys(values).forEach((key) => {
    if (
      StringUtils.isEmpty(values[key]) ||
      (typeof values === 'object' && values[key].length === 0)
    ) {
      delete values[key]
    }
    return key
  })
  return values
}

export const replaceNullToEmpty = (values) => {
  const items = { ...values }
  Object.keys(items).forEach((key) => {
    if (items[key] === null) {
      items[key] = ''
    }
  })

  return items
}

export const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], deepMerge(target[key], source[key]))
  }
  Object.assign(target || {}, source)
  return target
}

export const hasEmptyValue = (obj) => {
  if (!obj) {
    return true
  }

  let count = 0
  const arr = Object.values(obj)

  arr.forEach((item) => {
    if (item) {
      count++
    }
  })

  return count !== arr.length
}

export const getObjectChanged = ({ initialValues, changedValues }) => {
  if (
    !initialValues ||
    !changedValues ||
    typeof initialValues !== 'object' ||
    typeof changedValues !== 'object'
  )
    return {}

  let result = {}
  Object.entries(changedValues).forEach(([key, value]) => {
    if (initialValues[key] !== value) {
      result[key] = value
    }
  })

  return result
}

export const isNil = (value) => value === undefined || value === null

export default {
  get,
  isNil,
  isObjectEmpty,
  allObjectKeysIsValid,
  converObjToArray,
  getMinObjValue,
  getMaxObjValue,
  hasEmptyValue,
  sanitizeObject,
  replaceNullToEmpty,
  deepMerge,
  getObjectChanged
}
