import locale from './locale'

export const INVALID_REGEX = /[^0-9|^a-z|^/|^ ]/gi

export const isValid = (value) => {
  if (!value) {
    return true
  }

  if (!value?.trim()) {
    return false
  }

  const isInvalid = value.match(INVALID_REGEX)

  return !isInvalid
}

export function companyNumber(message = locale.string.companyNumber) {
  return this.test('companyNumber', message, isValid)
}

export default companyNumber
