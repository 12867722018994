import locale from './locale'

export const ZIP_REGEX = /^\d{5}-?\d{3}$/

export const isZipCode = (value) => ZIP_REGEX.test(value)

export function zipCode(message = locale.string.zipCode) {
  return this.test('zipCode', message, isZipCode)
}

export default zipCode
