import Fingerprint2 from 'fingerprintjs2'

/**
 * Signature method generate User Fringerprint and Geolocation
 *
 * @export
 * @return {array} return.errors Array with errors if to occur
 * @return {promise} return.getId Fingerprint id
 * @return {promise} return.getGeolocation Geolocation object with all user coordination
 */

function Signature() {
  const self = this
  this.values = undefined
  this.geolocation = {}
  this.errors = []

  const generateFingerprintId = () => {
    try {
      if (self.values.length > 0) {
        return Fingerprint2.x64hash128(self.values.join(''), 31)
      } else {
        console.error("Error on generate Fingerprint ID. Don't has user data")
      }
    } catch (e) {
      self.errors.push(e)
      console.error(e)
    }
  }

  const factoryId = async () => {
    const components = await Fingerprint2.getPromise()
    self.values = components.map((component) => component.value)

    return generateFingerprintId()
  }

  const getId = () => {
    try {
      if (window.requestIdleCallback) {
        return new Promise((resolve) => {
          requestIdleCallback(async function () {
            const id = await factoryId()
            resolve(id)
          })
        })
      } else {
        return new Promise((resolve) => {
          setTimeout(async function () {
            const id = await factoryId()
            resolve(id)
          }, 500)
        })
      }
    } catch (e) {
      self.errors.push(e)
      console.error(e)
    }
  }

  const getGeolocation = () => {
    let promise
    try {
      if ('geolocation' in navigator) {
        promise = new Promise((resolve, reject) =>
          navigator.geolocation.getCurrentPosition(resolve, reject)
        )
      } else {
        promise = Promise.reject(
          new Error(
            "I'm sorry, but geolocation services are not supported by your browser."
          )
        )
      }
    } catch (e) {
      self.errors.push(e)
      console.error(e)
    }
    return promise
  }

  return {
    errors: this.errors,
    getId,
    getGeolocation
  }
}

export default Signature
