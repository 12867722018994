export const isAdmin = (user) => user?.roles?.find((role) => role === 'ADMIN')

export const isRH = (user) => user?.roles?.find((role) => role === 'RH')

export const hasBackofficeAccess = (user) =>
  isAdmin(user)?.length > 0 || isRH(user)?.length > 0

export default {
  isAdmin,
  isRH,
  hasBackofficeAccess
}
