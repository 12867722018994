export const dispatchCustomEvent = (eventId) => (detail) =>
  global.dispatchEvent(new CustomEvent(eventId, { detail }))

export const stopEvent = (event) => {
  event.stopPropagation()
  event.preventDefault()
}

export default {
  dispatchCustomEvent,
  stopEvent
}
