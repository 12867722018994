import { isValid as isValidCnpj } from '../cnpj'
import { isValid as isValidCpf } from '../cpf'
import locale from './locale'

export const isValid = (value) => {
  if (!value) {
    return true
  }

  const size = value.length

  if (size === 11) {
    return isValidCpf(value)
  }

  if (size === 14) {
    return isValidCnpj(value, true)
  }

  return false
}

export function identificationDocument(message = locale.string.identificationDocument) {
  return this.test('identificationDocument', message, isValid)
}

export default identificationDocument
