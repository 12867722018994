const TENANTS_BASE_URL = {
  abc: 'contrateaqui.abcbrasil.com.br',
  paketa: 'paketa.com.br',
  sahcred: 'sahcred.paketa.com.br'
}

const envs = ['', 'dev.', 'hml.', 'uat.']

const configurations = {
  abc: {
    organization: 'REACT_APP_AUTH0_ORGANIZATION_ABC',
    connect: 'abc'
  },
  paketa: {
    organization: 'REACT_APP_AUTH0_ORGANIZATION_PAKETA',
    connection: 'paketa'
  },
  sahcred: {
    organization: 'REACT_APP_AUTH0_ORGANIZATION_SAHCRED',
    connection: 'sahcred'
  }
}

const hostnames = {
  abc: [
    ...envs.map((env) => `${env}${TENANTS_BASE_URL.abc}`),
    ...envs.map((env) => `cockpit.${env}${TENANTS_BASE_URL.abc}`),
    ...envs.map((env) => `bko.${env}${TENANTS_BASE_URL.abc}`)
  ],
  paketa: [
    ...envs.map((env) => `consignado.${env}${TENANTS_BASE_URL.paketa}`),
    ...envs.map((env) => `cockpit.${env}${TENANTS_BASE_URL.paketa}`),
    ...envs.map((env) => `bko.${env}${TENANTS_BASE_URL.paketa}`),
    'localhost'
  ],
  sahcred: [
    ...envs.map((env) => `consignado.${env}${TENANTS_BASE_URL.sahcred}`),
    ...envs.map((env) => `cockpit.${env}${TENANTS_BASE_URL.sahcred}`),
    ...envs.map((env) => `bko.${env}${TENANTS_BASE_URL.sahcred}`)
  ]
}

export const getTenant = () => {
  const hostname = window.location.hostname
  let tenant

  Object.keys(hostnames).forEach((key) => {
    if (hostnames[key].find((data) => data === hostname)) {
      tenant = key
    }
  })

  return tenant
}

export const getConfiguration = () => configurations[getTenant()] || {}

export default {
  getConfiguration,
  getTenant
}
