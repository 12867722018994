import locale from './locale'

export function size(max, message = locale.number.size) {
  return this.test({
    name: 'size',
    message,
    params: { max },
    test(value) {
      const newValue = value || ''

      return newValue.toString().length <= max
    }
  })
}

export default size
