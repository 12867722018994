// @ts-ignore
import Billet from 'boleto-validator'
import moment from './moment'

const LIMIT_DIGIT_VALUE = 10
const LIMIT_DIGIT_DUE_DATE = 14
const LIMIT_DIGIT_BANK = 3
const BASE_BACEN_DATE = '07/10/1997'

const throwError = () => {
  throw new Error('Invalid Billet')
}

export const isValidBillet = (line) => {
  let valid = false

  if (line) {
    Billet.boleto(line, (_, isValid) => {
      valid = !!isValid
    })
  }

  return valid
}

export const getValue = (line) => {
  if (!isValidBillet(line)) {
    throwError()
  }

  const value = line.slice(line.length - LIMIT_DIGIT_VALUE)

  const decimal = value.slice(0, value.length - 2)
  const cents = value.slice(value.length - 2, value.length)

  const numberFormatted = `${decimal}.${cents}`

  return parseFloat(numberFormatted)
}

export const getDueDate = (line) => {
  if (!isValidBillet(line)) {
    throwError()
  }

  const dueDate = line.slice(
    line.length - LIMIT_DIGIT_DUE_DATE,
    line.length - LIMIT_DIGIT_VALUE
  )

  return moment(BASE_BACEN_DATE, 'DD/MM/YYYY').add(dueDate, 'days')
}

export const getBankCode = (line) => {
  if (!isValidBillet(line)) {
    throwError()
  }

  return line.slice(0, LIMIT_DIGIT_BANK)
}

export const getPropertiesFromBillet = (line) => ({
  bank: getBankCode(line),
  dueDate: getDueDate(line),
  value: getValue(line)
})

export default {
  throwError,
  isValidBillet,
  getValue,
  getDueDate,
  getBankCode,
  getPropertiesFromBillet
}
