const maskPhone = (phone) => {
  const values = {
    14: `${phone.substr(0, 3)} (${phone.substr(3, 2)}) ${phone.substr(
      5,
      5
    )}-${phone.substr(10)}`,
    13: `${phone.substr(0, 3)} (${phone.substr(3, 2)}) ${phone.substr(
      5,
      4
    )}-${phone.substr(9)}`,
    11: `(${phone.substr(0, 2)}) ${phone.substr(2, 5)}-${phone.substr(7)}`,
    10: `(${phone.substr(0, 2)}) ${phone.substr(2, 4)}-${phone.substr(6)}`
  }

  return values[phone.length]
}

export function format(phone) {
  if (!phone) {
    return phone
  }

  return maskPhone(phone) || phone
}

export const removeDDI = (phone) => phone.replace('+55', '')

export const strip = (phone) => phone.replace(/[^\d]/g, '')

export default {
  format,
  removeDDI,
  strip
}
