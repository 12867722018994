import { filterNotNums } from './string'

export const formatToMoney = (number, options, country = 'pt-BR') => {
  if (isNaN(number) || number === null) {
    return ''
  }

  const { style, currency = 'BRL', minimumFractionDigits = 2 } = options || {}

  return new Intl.NumberFormat(country, {
    style,
    currency,
    minimumFractionDigits
  }).format(number)
}

export const convertCurrencyToNumber = (currency) => {
  if (typeof currency === 'number') return currency

  const [integer = '', decimal = ''] = currency?.split(',')
  const currencyNumbers = filterNotNums(integer)

  return Number(`${currencyNumbers}.${decimal}`)
}

export const formatCurrency = (price) => {
  return formatToMoney(price, { style: 'currency' })
}

export const sanitizeMoney = (rawValue) => {
  if (!rawValue || rawValue === null || !/\d/.test(rawValue)) {
    return '0,00'
  }

  const value = rawValue.toString()?.replace(/[^\d,.]+/g, '')
  const dotCount = value.replace(/[^.]/g, '').length
  const commaCount = value.replace(/[^,]/g, '').length

  if (commaCount <= 0 && dotCount <= 0) {
    return `${value},00`
  }

  if ((commaCount === 1 && dotCount <= 0) || (dotCount === 1 && commaCount <= 0)) {
    const valueWithDot = value?.split(commaCount === 1 && dotCount <= 0 ? ',' : '.')
    const decimal = valueWithDot[1].slice(0, 2)
    return `${valueWithDot[0]},${decimal.length === 1 ? `${decimal}0` : decimal}`
  }

  const splittedNumber = value.replace(/[,.]/g, '_').split('_')

  let result = ''

  splittedNumber.forEach((number, index) => {
    let sum = number
    const length = number.length

    if (index === splittedNumber.length - 1) {
      sum = `,${length === 1 ? `${sum}0` : sum.slice(0, 2)}`
    }

    result = `${result}${sum}`
  })

  return result
}

export default {
  formatToMoney,
  formatCurrency,
  convertCurrencyToNumber,
  sanitizeMoney
}
