import * as yup from 'yup'
import { setLocale } from './set-locale'
import phone from './phone'
import cellphone from './cellphone'
import zipCode from './zip-code'
import cnpj from './cnpj'
import brazilPhone from './brazil-phone'
import cpf from './cpf'
import ddd from './ddd'
import size from './size'
import identificationDocument from './identification-document'
import billet from './billet'
import isValid from './date'
import companyNumber from './company-number'
import email from './email'
import locale from './locale'
import disableFuture from './disable-future'
import documentText from './document-text'

setLocale()

yup.addMethod(yup.string, 'phone', phone)
yup.addMethod(yup.string, 'cellphone', cellphone)
yup.addMethod(yup.string, 'brazilPhone', brazilPhone)
yup.addMethod(yup.string, 'ddd', ddd)
yup.addMethod(yup.string, 'zipCode', zipCode)
yup.addMethod(yup.string, 'cnpj', cnpj)
yup.addMethod(yup.string, 'cpf', cpf)
yup.addMethod(yup.string, 'identificationDocument', identificationDocument)
yup.addMethod(yup.string, 'email', email)
yup.addMethod(yup.string, 'billet', billet)
yup.addMethod(yup.string, 'companyNumber', companyNumber)
yup.addMethod(yup.string, 'documentText', documentText)
yup.addMethod(yup.number, 'size', size)
yup.addMethod(yup.date, 'isValid', isValid)
yup.addMethod(yup.date, 'disableFuture', disableFuture)

export * from 'yup'
export default {
  ...yup,
  locale,
  setLocale
}
