const getId = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0
    // eslint-disable-next-line
    const value = char === 'x' ? random : (random & 0x3 | 0x8)
    return value.toString(16)
  })

export default {
  getId
}
